// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import ShippingChargeCalculator from "../../blocks/ShippingChargeCalculator/src/ShippingChargeCalculator";
import ConnectSocialMediaAccount from "../../blocks/ConnectSocialMediaAccount/src/ConnectSocialMediaAccount";
import OrderManagement2 from "../../blocks/OrderManagement2/src/OrderManagement2";
import AddressManagement from "../../blocks/AddressManagement/src/AddressManagement";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Dashboard4 from "../../blocks/Dashboard4/src/Dashboard4";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import Payments from "../../blocks/Payments/src/Payments";
import PhoneLogin from "../../blocks/PhoneLogin/src/PhoneLogin";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import SplashScreen2 from "../../blocks/SplashScreen2/src/SplashScreen2";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import FacebookLogin from "../../blocks/FacebookLogin/src/FacebookLogin";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Share from "../../blocks/Share/src/Share";
import GoogleLogin14 from "../../blocks/GoogleLogin14/src/GoogleLogin14";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Catalogue from "../../blocks/Catalogue/src/Catalogue";
import Sms2 from "../../blocks/Sms2/src/Sms2";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import CategoriessubCategories from "../../blocks/CategoriessubCategories/src/CategoriessubCategories";
import SignuploginModule2 from "../../blocks/SignuploginModule2/src/SignuploginModule2";
import FilterItems from "../../blocks/FilterItems/src/FilterItems";
import CfInventoryManagement7 from "../../blocks/CfInventoryManagement7/src/CfInventoryManagement7";
import AccountCreation from "../../blocks/AccountCreation/src/AccountCreation";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import InteractiveFaqs from "../../blocks/InteractiveFaqs/src/InteractiveFaqs";
import Reviews from "../../blocks/Reviews/src/Reviews";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import Sorting6 from "../../blocks/Sorting6/src/Sorting6";
import CouponCodeGenerator from "../../blocks/CouponCodeGenerator/src/CouponCodeGenerator";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import Profilebio from "../../blocks/Profilebio/src/Profilebio";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";



const routeMap = {
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
ShippingChargeCalculator:{
 component:ShippingChargeCalculator,
path:"/ShippingChargeCalculator"},
ConnectSocialMediaAccount:{
 component:ConnectSocialMediaAccount,
path:"/ConnectSocialMediaAccount"},
OrderManagement2:{
 component:OrderManagement2,
path:"/OrderManagement2"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
Dashboard4:{
 component:Dashboard4,
path:"/Dashboard4"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
Payments:{
 component:Payments,
path:"/Payments"},
PhoneLogin:{
 component:PhoneLogin,
path:"/PhoneLogin"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
SplashScreen2:{
 component:SplashScreen2,
path:"/SplashScreen2"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
FacebookLogin:{
 component:FacebookLogin,
path:"/FacebookLogin"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Share:{
 component:Share,
path:"/Share"},
GoogleLogin14:{
 component:GoogleLogin14,
path:"/GoogleLogin14"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Sms2:{
 component:Sms2,
path:"/Sms2"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
CategoriessubCategories:{
 component:CategoriessubCategories,
path:"/CategoriessubCategories"},
SignuploginModule2:{
 component:SignuploginModule2,
path:"/SignuploginModule2"},
FilterItems:{
 component:FilterItems,
path:"/FilterItems"},
CfInventoryManagement7:{
 component:CfInventoryManagement7,
path:"/CfInventoryManagement7"},
AccountCreation:{
 component:AccountCreation,
path:"/AccountCreation"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
InteractiveFaqs:{
 component:InteractiveFaqs,
path:"/InteractiveFaqs"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
Sorting6:{
 component:Sorting6,
path:"/Sorting6"},
CouponCodeGenerator:{
 component:CouponCodeGenerator,
path:"/CouponCodeGenerator"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
Profilebio:{
 component:Profilebio,
path:"/Profilebio"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;